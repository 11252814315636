// Copyright 2024 Merit International Inc. All Rights Reserved

import { Body, Heading, useTheme } from "@merit/frontend-components";
import {
  FloatingPortal,
  useDismiss,
  useFloating,
  useInteractions,
  useRole,
} from "@floating-ui/react";
import { Helpers } from "@merit/frontend-utils";
import { type Placement, offset } from "@floating-ui/react-native";
import { Platform, StyleSheet, View } from "react-native";
import { useEffect, useState } from "react";
import { useHover } from "react-native-web-hooks";
import { useNavigation } from "@react-navigation/native";
import type { NativeStackNavigationProp } from "@react-navigation/native-stack";
import type { ReactNode } from "react";
import type { RouteParams } from "@src/Router";
import type { ViewStyle } from "react-native";

const { generateTestIdProps } = Helpers;
const SCREEN_NAME = "NavMenu";
type Props = {
  readonly title?: string;
  readonly popoverContent: ReactNode;
  readonly popoverPlacement?: Placement;
  readonly customButtonContent?: ReactNode;
  readonly disabled?: boolean;
  readonly isActive?: boolean;
};

export const NavMenu = ({
  customButtonContent,
  disabled = false,
  isActive = false,
  popoverContent,
  popoverPlacement = "bottom-end",
  title,
}: Props) => {
  const { theme } = useTheme();
  const [isPopoverOpen, setIsPopoverOpen] = useState<boolean>(false);
  const navigation = useNavigation<NativeStackNavigationProp<RouteParams>>();

  const { context, floatingStyles, refs } = useFloating({
    middleware: [offset({ mainAxis: 16 })],
    onOpenChange: () => {
      setIsPopoverOpen(!isPopoverOpen);
    },
    open: isPopoverOpen,
    placement: popoverPlacement,
  });

  const dismiss = useDismiss(context, {
    escapeKey: true,
    referencePress: true,
  });

  const role = useRole(context, {
    role: "menu",
  });

  const { getFloatingProps, getReferenceProps } = useInteractions([dismiss, role]);
  const isHovered = useHover(refs.reference);

  // Close popover when changing screens
  useEffect(
    () =>
      navigation.addListener("state", () => {
        setIsPopoverOpen(false);
      }),
    [navigation]
  );

  const styles = StyleSheet.create<{
    readonly button: ViewStyle;
    readonly hovered: ViewStyle;
    readonly popoverHighlightBar: ViewStyle;
    readonly popoverHighlightBarWrapper: ViewStyle;
    readonly popoverWrapper: ViewStyle;
    readonly buttonDisabled: ViewStyle;
  }>({
    button: {
      borderRadius: 2,
      ...Platform.select({
        web: {
          cursor: "pointer",
        },
      }),
      padding: 8,
    },
    buttonDisabled: {
      borderRadius: 2,
      opacity: 0.5,
      padding: 8,
    },
    hovered: {
      backgroundColor: theme.colors.surface.hovered,
    },
    popoverHighlightBar: {
      alignSelf: popoverPlacement === "bottom-start" ? "flex-start" : "flex-end",
      backgroundColor: "black",
      borderTopLeftRadius: popoverPlacement === "bottom-start" ? 4 : undefined,
      borderTopRightRadius: popoverPlacement === "bottom-start" ? undefined : 4,
      height: 2,
      width: refs.reference.current?.getBoundingClientRect().width,
    },
    popoverHighlightBarWrapper: {
      flex: 1,
      flexDirection: "column",
    },
    popoverWrapper: {
      ...theme.elevations.depth4,
      borderRadius: 4,
    },
  });

  const disabledButton =
    customButtonContent === undefined ? (
      <View style={styles.buttonDisabled}>
        <Body>{title}</Body>
      </View>
    ) : (
      customButtonContent
    );

  return (
    <>
      {disabled ? (
        disabledButton
      ) : (
        <div ref={refs.setReference} {...getReferenceProps()}>
          {customButtonContent === undefined ? (
            <View
              style={[styles.button, isHovered && styles.hovered]}
              {...generateTestIdProps({
                elementName: title?.replaceAll("& ", "") ?? "navTab",
                screenName: SCREEN_NAME,
              })}
            >
              {isActive ? (
                <Heading bold level="5">
                  {title}
                </Heading>
              ) : (
                <Body>{title}</Body>
              )}
            </View>
          ) : (
            customButtonContent
          )}
        </div>
      )}

      {isPopoverOpen && (
        <FloatingPortal>
          <div
            ref={refs.setFloating}
            style={{ ...floatingStyles, zIndex: 2 }}
            {...getFloatingProps()}
          >
            <View style={styles.popoverWrapper}>
              <View style={styles.popoverHighlightBarWrapper}>
                <View style={styles.popoverHighlightBar} />
              </View>
              {popoverContent}
            </View>
          </div>
        </FloatingPortal>
      )}
    </>
  );
};
