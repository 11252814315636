// Copyright 2023 Merit International Inc. All Rights Reserved

import { Body, Button, Heading, Switch, TextInput, useTheme } from "@merit/frontend-components";
import { Helpers } from "@merit/frontend-utils";
import { SCREEN_NAME } from "../ConfigureTemplate";
import { StyleSheet, View } from "react-native";
import { TemplateFieldListItem } from "./TemplateFieldListItem";
import { VerticalSpacer } from "../../../components/Spacer";
import React, { useCallback, useState } from "react";
import type { FormikProps } from "formik";
import type { OPTestProps } from "@src/types/TestProps";

export type FormFields = {
  readonly fieldID: string;
  readonly name: string;
  readonly description: string;
  readonly nameToDisplay: string;
  readonly contact: boolean;
  readonly isInherited: boolean;
  readonly fieldKindID?: string;
  readonly isVisible: boolean;
  readonly isShareable: boolean;
};

export type FormValues = {
  readonly fields: readonly FormFields[];
};

type Props = {
  readonly displayFieldsListModal: () => void;
  readonly formProps: FormikProps<FormValues>;
  readonly requiredTemplateFieldIDs: readonly string[];
};

const { Some } = Helpers;

export const TemplateFields = ({
  displayFieldsListModal,
  formProps,
  requiredTemplateFieldIDs,
}: Props) => {
  const { theme } = useTheme();

  const [fieldIdToExpand, setFieldIdToExpand] = useState<string>();

  const styles = StyleSheet.create({
    fieldExpandView: {
      backgroundColor: theme.colors.surface.subdued,
      paddingHorizontal: 32,
      paddingVertical: theme.spacing.xxl,
    },
    noFields: {
      backgroundColor: theme.colors.background.white,
      paddingHorizontal: 32,
      paddingVertical: 22,
    },
    switchContainer: {
      alignItems: "center",
      display: "flex",
      flexDirection: "row",
      justifyContent: "space-between",
    },
  });

  const { getFieldMeta, setFieldValue, setValues, values } = formProps;

  const getFieldInitialValue = useCallback(
    (fieldIndex: number, fieldName: "isShareable" | "isVisible") => {
      const fieldInitialValue = getFieldMeta<FormFields>(`fields[${fieldIndex}]`).initialValue;
      if (Some(fieldInitialValue)) {
        return fieldInitialValue[fieldName];
      }

      return false;
    },
    [getFieldMeta]
  );

  const errorText = (fieldName: string, testProps: OPTestProps) => {
    if (getFieldMeta(fieldName).touched && Some(getFieldMeta(fieldName).error)) {
      return (
        <>
          <VerticalSpacer size={theme.spacing.s} />
          <Body color={theme.colors.text.alert.critical} testProps={testProps}>
            {getFieldMeta(fieldName).error}
          </Body>
        </>
      );
    }

    return <></>;
  };

  const moveUp = (templateField: FormFields, rowIndex: number) => {
    const fieldToMoveDown = [...values.fields].splice(rowIndex - 1, 1)[0];
    const fields = values.fields.map((field, currentIndex) => {
      if (rowIndex === currentIndex) {
        return fieldToMoveDown;
      }

      if (rowIndex - 1 === currentIndex) {
        return templateField;
      }

      return field;
    });

    setValues({ fields });
  };

  const moveDown = (templateField: FormFields, rowIndex: number) => {
    const fieldToMoveUp = [...values.fields].splice(rowIndex + 1, 1)[0];
    const fields = values.fields.map((field, currentIndex) => {
      if (rowIndex === currentIndex) {
        return fieldToMoveUp;
      }

      if (rowIndex + 1 === currentIndex) {
        return templateField;
      }

      return field;
    });

    setValues({ fields });
  };

  return (
    <>
      <Heading
        bold
        level="3"
        testProps={{ elementName: "configureTemplateFieldsTabFieldsText", screenName: SCREEN_NAME }}
      >
        Fields
      </Heading>

      <View
        style={{
          alignItems: "center",
          flexDirection: "row",
          justifyContent: "space-between",
        }}
      >
        <Body>These are the inherited fields and additional fields</Body>
        <Button
          iconLeft="addSmallDefault"
          onPress={displayFieldsListModal}
          size="small"
          testProps={{
            elementName: "configureTemplateFieldsTabAddFieldButton",
            screenName: SCREEN_NAME,
          }}
          text="Add field"
          type="secondary"
        />
      </View>

      <VerticalSpacer size={theme.spacing.xxl} />

      {values.fields.length > 0 ? (
        <>
          {values.fields.map((templateField, index) => (
            <View key={templateField.fieldID}>
              <TemplateFieldListItem
                field={templateField}
                fieldIdToExpand={fieldIdToExpand}
                index={index}
                onExpand={() => {
                  setFieldIdToExpand(prevState =>
                    Some(prevState) && prevState === templateField.fieldID
                      ? undefined
                      : templateField.fieldID
                  );
                }}
                onMoveDown={() => {
                  if (index < values.fields.length - 1) {
                    moveDown(templateField, index);
                  }
                }}
                onMoveUp={() => {
                  if (index > 0) {
                    moveUp(templateField, index);
                  }
                }}
                templateFieldsLength={values.fields.length}
              />
              {Some(fieldIdToExpand) && fieldIdToExpand === templateField.fieldID && (
                <View style={styles.fieldExpandView}>
                  <TextInput
                    label="Template field name"
                    onChangeText={value => {
                      setFieldValue(`fields[${index}].name`, value);
                    }}
                    placeholder="Template field name"
                    testProps={{
                      elementId: templateField.fieldID,
                      elementName: "configureTemplateFieldsTabFieldsListItemFieldNameTextInput",
                      screenName: SCREEN_NAME,
                    }}
                    value={templateField.name}
                  />
                  {errorText(`fields[${index}].name`, {
                    elementId: templateField.fieldID,
                    elementName: "configureTemplateFieldsTabFieldsListItemFieldNameTextInputError",
                    screenName: SCREEN_NAME,
                  })}
                  <VerticalSpacer size={theme.spacing.xxl} />
                  <TextInput
                    label="Description"
                    numberOfLines={4}
                    onChangeText={value => {
                      setFieldValue(`fields[${index}].description`, value);
                    }}
                    placeholder="Description"
                    testProps={{
                      elementId: templateField.fieldID,
                      elementName: "configureTemplateFieldsTabFieldsListItemDescription",
                      screenName: SCREEN_NAME,
                    }}
                    value={templateField.description}
                  />
                  {errorText(`fields[${index}].description`, {
                    elementId: templateField.fieldID,
                    elementName: "configureTemplateFieldsTabFieldsListItemDescriptionError",
                    screenName: SCREEN_NAME,
                  })}
                  <VerticalSpacer size={theme.spacing.s} />
                  <Body>300 maximum character limit</Body>
                  <VerticalSpacer size={theme.spacing.xxl} />

                  <Heading level="5">Visibility</Heading>
                  <View style={styles.switchContainer}>
                    <Body>If enabled, recipients will be able to see this field</Body>
                    <Switch
                      accessibilityLabel="toggle-visibility"
                      disabled={
                        getFieldInitialValue(index, "isVisible") &&
                        requiredTemplateFieldIDs.includes(templateField.fieldID)
                      }
                      onToggle={value => {
                        setFieldValue(`fields[${index}].isVisible`, value);
                        if (!value) {
                          setFieldValue(`fields[${index}].isShareable`, false);
                        }
                      }}
                      value={templateField.isVisible}
                    />
                  </View>
                  <VerticalSpacer size={theme.spacing.xxl} />

                  <Heading level="5">Can Share</Heading>
                  <View style={styles.switchContainer}>
                    <Body>
                      If enabled, recipients can share this field with other people and
                      organizations.
                    </Body>
                    <Switch
                      accessibilityLabel="toggle-shareability"
                      disabled={
                        !values.fields[index].isVisible ||
                        (getFieldInitialValue(index, "isShareable") &&
                          requiredTemplateFieldIDs.includes(templateField.fieldID))
                      }
                      onToggle={value => {
                        setFieldValue(`fields[${index}].isShareable`, value);
                      }}
                      value={templateField.isShareable}
                    />
                  </View>
                </View>
              )}
              {/* TODO: Current response does not have validations*/}
              {/* TODO: Add field mapping */}
            </View>
          ))}
        </>
      ) : (
        <View style={styles.noFields}>
          <Body>Template fields will appear once they are added</Body>
        </View>
      )}
    </>
  );
};
