// Copyright 2023 Merit International Inc. All Rights Reserved
/* eslint-disable @typescript-eslint/no-require-imports */

import { datadogRum as DdRum } from "@datadog/browser-rum";
import { Helpers } from "@merit/frontend-utils";
import { asyncWithLDProvider } from "launchdarkly-react-client-sdk";
import { initDatadog } from "@src/utils/datadog";
import { useApi } from "../api/api";
import {
  useAppConstantsStore,
  useAuthStore,
  useBaseFieldIdStore,
  useConfigurationStore,
} from "../stores";
import { useEffect, useState } from "react";
import { useFonts } from "expo-font";
import Constants from "expo-constants";
import type { Configuration } from "@src/configuration";
import type { LDEvaluationDetail, ProviderConfig } from "launchdarkly-react-client-sdk";
import type { LDMultiKindContext } from "launchdarkly-js-client-sdk";

export const useInitializeApp = () => {
  const [isAppLoaded, setIsAppLoaded] = useState<boolean>(false);
  const [datadogInitialized, setDatadogInitialized] = useState<boolean>(false);
  const constants = useAppConstantsStore();
  const { fieldIds, updateFieldIds } = useBaseFieldIdStore();
  const { configuration, updateConfiguration } = useConfigurationStore();
  const { profile, selectedOrgId } = useAuthStore();
  const { api } = useApi();
  const [LaunchDarklyProvider, setLaunchDarklyProvider] =
    useState<({ children }: { readonly children: React.ReactNode }) => JSX.Element>();
  const config = Constants.manifest?.extra as Configuration;

  const { Some } = Helpers;
  const datadogRequired = Some(config.datadog.enabled);

  useEffect(() => {
    const initializeDatadog = async () => {
      if (datadogRequired) {
        if (typeof config.datadog.applicationId !== "string") {
          // eslint-disable-next-line no-console
          console.error(
            "Datadog application ID must be defined as a string if datadog is enabled."
          );

          return;
        }
        if (typeof config.datadog.clientToken !== "string") {
          // eslint-disable-next-line no-console
          console.error("Datadog client token must be defined as a string if datadog is enabled.");

          return;
        }

        await initDatadog({
          allowedTracingUrls: config.datadog.allowedTracingUrls,
          applicationId: config.datadog.applicationId,
          clientToken: config.datadog.clientToken,
          environment: config.environmentSuffix ?? config.environment,
          serviceName: "org-portal-frontend",
        });
        setDatadogInitialized(true);
      }
    };
    initializeDatadog();
  }, [config, datadogRequired]);

  const [fontsLoaded] = useFonts({
    ProximaNova: require("../assets/fonts/ProximaNova.otf"),
    ProximaNovaBold: require("../assets/fonts/ProximaNovaBold.otf"),
    ProximaNovaMedium: require("../assets/fonts/ProximaNovaMedium.otf"),
    ProximaNovaSemiBold: require("../assets/fonts/ProximaNovaSemiBold.otf"),
  });

  useEffect(() => {
    const initializeStoreData = async () => {
      if (configuration === undefined) {
        await updateConfiguration();
      }

      if (configuration === undefined) {
        // Shouldn't be possible
        // eslint-disable-next-line no-console
        console.error("configuration endpoint failed to return configuration");

        return;
      }

      if (fieldIds === undefined && selectedOrgId !== null) {
        await updateFieldIds(api, configuration, constants, selectedOrgId);
      }
    };

    initializeStoreData();
  }, [api, configuration, constants, fieldIds, updateConfiguration, updateFieldIds, selectedOrgId]);

  useEffect(() => {
    const loadLDProvider = async () => {
      if (configuration === undefined) {
        return;
      }

      if (!("launchDarklyClientID" in configuration)) {
        // eslint-disable-next-line no-console
        console.error("launchDarklyClientID missing in configuration - LD will fail");
      }

      const ctx: LDMultiKindContext = {
        kind: "multi",
        org: {
          key: selectedOrgId ?? "",
        },
        user: {
          key: profile?.entityID,
        },
      };

      const ldProviderConfig: ProviderConfig = {
        clientSideID: configuration.launchDarklyClientID,
        context: profile === null || selectedOrgId === null ? undefined : ctx,
        options: {
          inspectors: [
            {
              method: (key: string, detail: LDEvaluationDetail) => {
                DdRum.addFeatureFlagEvaluation(key, detail.value);
              },
              name: "dd-inspector",
              type: "flag-used",
            },
          ],
        },
      };

      const LDProvider = await asyncWithLDProvider(ldProviderConfig);

      setLaunchDarklyProvider(() => LDProvider);
    };

    if (configuration !== undefined && LaunchDarklyProvider === undefined) {
      loadLDProvider();
    }
  }, [LaunchDarklyProvider, configuration, profile, selectedOrgId]);

  const datadogWorkDone = !datadogRequired || datadogInitialized;
  useEffect(() => {
    if (!isAppLoaded && fontsLoaded && datadogWorkDone && configuration !== undefined) {
      setIsAppLoaded(true);
    }
  }, [configuration, fontsLoaded, isAppLoaded, datadogWorkDone]);

  return { LaunchDarklyProvider, isAppLoaded };
};
