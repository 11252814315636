import { datadogLogs } from "@datadog/browser-logs";
import { datadogRum } from "@datadog/browser-rum";
import type { InitConfiguration } from "@datadog/browser-core";
import type { LogsInitConfiguration } from "@datadog/browser-logs";
import type { RumInitConfiguration } from "@datadog/browser-rum";

type InitDatadogOptions = {
  readonly allowedTracingUrls: RumInitConfiguration["allowedTracingUrls"];
  readonly applicationId: RumInitConfiguration["applicationId"];
  readonly clientToken: InitConfiguration["clientToken"];
  readonly environment: InitConfiguration["env"];
  readonly serviceName: InitConfiguration["service"];
};

const DATADOG_SITE = "datadoghq.com";
type InitDatadog = (options: InitDatadogOptions) => Promise<void>;

export const initDatadog: InitDatadog = options => {
  const rumConfig: RumInitConfiguration = {
    allowedTracingUrls: options.allowedTracingUrls,
    applicationId: options.applicationId,
    clientToken: options.clientToken,
    defaultPrivacyLevel: "mask",
    enableExperimentalFeatures: ["feature_flags"],
    env: options.environment,
    service: options.serviceName,
    sessionSampleRate: 100,
    site: DATADOG_SITE,
    trackFrustrations: true,
    trackLongTasks: true,
    // track resource loading
    trackResources: true,
    // track user interactions such as tapping on a button. You can use the 'accessibilityLabel' element property to give the tap action a name, otherwise the element type is reported.
    trackUserInteractions: true,
    trackViewsManually: true,
  };

  const logsConfig: LogsInitConfiguration = {
    ...rumConfig,
    // eslint-disable-next-line consistent-return
    beforeSend: event => {
      if (event.error?.stack?.includes("AbortError") ?? false) {
        return false;
      }
    },
    clientToken: options.clientToken,
    env: options.environment,
    forwardErrorsToLogs: true,
    service: options.serviceName,
    site: DATADOG_SITE,
  };

  return new Promise(resolve => {
    // eslint-disable-next-line functional/no-let
    let callbacksRemaining = 2;
    datadogRum.onReady(() => {
      callbacksRemaining -= 1;
      if (callbacksRemaining === 0) {
        resolve();
      }
    });
    datadogLogs.onReady(() => {
      callbacksRemaining -= 1;
      if (callbacksRemaining === 0) {
        resolve();
      }
    });
    datadogRum.init(rumConfig);
    datadogLogs.init(logsConfig);
  });
};
