// Copyright 2024 Merit International Inc. All Rights Reserved

import { Body, useTheme } from "@merit/frontend-components";
import { Helpers } from "@merit/frontend-utils";
import { Platform, StyleSheet, TouchableWithoutFeedback, View } from "react-native";
import { ROUTES, type RouteParams } from "@src/Router";
import { useHover } from "react-native-web-hooks";
import { useLinkProps } from "@react-navigation/native";
import { useRef } from "react";
import type { ViewStyle } from "react-native";

type Props = {
  readonly title: string;
  readonly location: keyof RouteParams;
  readonly disabled?: boolean;
};
const { generateTestIdProps } = Helpers;
const SCREEN_NAME = "NavLink";
export const NavLink = ({ disabled = false, location, title }: Props) => {
  const { theme } = useTheme();
  const wrapperRef = useRef<View | null>(null);
  const isHovered = useHover(wrapperRef);
  const { onPress, ...linkProps } = useLinkProps<RouteParams>({
    to: ROUTES[location],
  });
  const styles = StyleSheet.create<{
    readonly button: ViewStyle;
    readonly hovered: ViewStyle;
    readonly buttonDisabled: ViewStyle;
  }>({
    button: {
      borderRadius: 2,
      ...Platform.select({
        web: {
          cursor: "pointer",
        },
      }),
      padding: 8,
    },
    // TODO(PF-1644): create web-specific component for disabled state with cursor: "not-allowed" css
    buttonDisabled: {
      borderRadius: 2,
      opacity: 0.5,
      padding: 8,
    },
    hovered: {
      backgroundColor: theme.colors.surface.hovered,
    },
  });

  return disabled ? (
    <View style={styles.buttonDisabled}>
      <Body>{title}</Body>
    </View>
  ) : (
    <View ref={wrapperRef}>
      <TouchableWithoutFeedback onPress={onPress} {...linkProps}>
        <View
          {...generateTestIdProps({
            elementName: title,
            screenName: SCREEN_NAME,
          })}
          style={[styles.button, isHovered && styles.hovered]}
        >
          <Body>{title}</Body>
        </View>
      </TouchableWithoutFeedback>
    </View>
  );
};
